<template>
  <section class="full-height">

    <h1>preview {{ file }} </h1>
    
    <div class="columns is-gapless is-marginless is-multiline m1rem">
      <SearchInput v-model="filterSearch" :label="'Search'" @change="updateSearch" />
      <MultiSelect v-model="filterRoles.selectValues" :label="getRolesFilterLabel()" @change="updateSelectedRoles" />
      <MultiSelect v-model="filterUseDepartments.selectValues" :label="getUserDepartmentsFilterLabel()" @change="updateSelectedUserDepartments" />
      <MultiSelect v-model="filterLocations.selectValues" :label="getLocationFilterLabel()" @change="updateSelectedLocations" />
      <MultiSelect v-model="filterSchedules.selectValues" :label="getScheduleFilterLabel()" @change="updateSelectedSchedules" />
      <MultiSelect v-model="filterCodeSchedules.selectValues" :label="getCodeScheduleFilterLabel()" @change="updateSelectedCodeSchedules" />
      <MultiSelect v-model="filterCodeSets.selectValues" :label="getCodeSetsFilterLabel()" @change="updateSelectedCodeSets" />
      <MultiSelect v-model="filterCodeTypes.selectValues" :label="getCodeTypeFilterLabel()" @change="updateSelectedCodeTypes" />
      <MultiSlider v-model="filterQuotum.value" :label="getQuotumFilterLabel()" :min="filterQuotum.min" 
        :max="filterQuotum.max" :step="1" :ticks="true" 
        @change="updateFilterQuotum" />
      <MultiSlider v-model="filterPerformance.value" :label="getPerformanceFilterLabel()" :min="filterPerformance.min" 
        :max="filterPerformance.max" :step="0.05" :ticks="true" 
        @change="updateFilterPerformance" />
      <MultiSlider v-model="filterCoverage.value" :label="getCoverageFilterLabel()" :min="filterCoverage.min" 
        :max="filterCoverage.max" :step="0.05" :ticks="true" 
        @change="updateFilterCoverage" />
      <MultiSlider v-if="showAvailability" v-model="filterAvailable.value" :label="getAvailableFilterLabel()" :min="filterAvailable.min" 
        :max="filterAvailable.max" :step="1" :ticks="true" 
        @change="updateFilterAvailable" />
      <MultiSlider v-model="filterAssigned.value" :label="getAssignedFilterLabel()" :min="filterAssigned.min" 
        :max="filterAssigned.max" :step="1" :ticks="true" 
        @change="updateFilterAssigned" />
      <MultiSlider v-model="filterAssignedPhase.value" :label="getAssignedPhaseFilterLabel()" :min="filterAssignedPhase.min" 
        :max="filterAssignedPhase.max" :step="1" :ticks="true" 
        @change="updateFilterAssignedPhase" />
      <MultiSlider v-model="filterHolidays.value" :label="getHolidaysFilterLabel()" :min="filterHolidays.min" 
        :max="filterHolidays.max" :step="1" :ticks="true" 
        @change="updateFilterHolidays" />
      
      <b-field>
          <b-switch v-model="showAvailability" @input="updateFilters" >show availability</b-switch>
          <b-switch v-model="showSteaksOnly" @input="updateFilters" >StreaksOnly</b-switch>
          <b-switch v-if="showSteaksOnly" v-model="hideLowMaxforStreaks" @input="updateFilters" >hideLowMaxForStreaks</b-switch>
          <b-switch v-model="locationMisMatch" @input="updateFilters" >LocationMisMatch</b-switch>
          <b-switch v-model="errorMisMatch" @input="updateFilters" >Errors</b-switch>
      </b-field>
      
      <b-field>
          <b-select placeholder="sort by" v-model="sortOptionSelected">
              <option
                  v-for="option in sortOptions"
                  :value="option"
                  :key="option">
                  {{ option }}
              </option>
          </b-select>
      </b-field>
      
      <div class="column">
        <b-button class="button" @click="update" @>update</b-button>
      </div>
    </div>


    <div ref="UserOverviewWrap" id="UserOverviewWrap" class="">

    </div>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
import axios_api from '@/plugins/axios_api';
import { API_ROOT } from '@/config/app.js';
import MultiSelect from "@/components/public/general/multiSelect.vue";
import MultiSlider from "@/components/public/general/multiSlider.vue";
import SearchInput from "@/components/public/general/searchInput.vue";

// import { Calc_TimeLeftMsg }         from '@/helpers/functions';
import { roundMaxDigits }         from '@/helpers/numbers';
// import { WEB_ROOT, API_ROOT } from '@/config/app.js';
// import { mapState } from 'vuex';

// import { DDMMYYYY_HHii } from '@/helpers/dates.js';
// const jsonFormat = require("json-format")

export default {
  name: "MaximusRun",
  components: {
      MultiSelect,
      MultiSlider,
      SearchInput
    },

  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,


      // WEB_ROOT            : WEB_ROOT,
      API_ROOT            : API_ROOT,
      SITE_ROOT           : process.env.VUE_APP_SITE_MAIN,

      companyId           : null,
      jsonPath            : null,
      runPath             : null,
      file                : null,
      json                : null,
      jsonFiltered        : null,
      
      filterSearch        : '',
      filterQuotum        :{
        min: 0,
        max: 100,
        value: [1, 100]
      },
      
      filterPerformance        :{
        min: 0,
        max: 2,
        value: [0, 2]
      },
      
      filterCoverage        :{
        min: 0,
        max: 1,
        value: [0, 1]
      },
      
      filterAvailable        :{
        min: 0,
        max: 100,
        value: [0, 100]
      },
      
      filterAssigned        :{
        min: 0,
        max: 50,
        value: [0, 50]
      },
      
      filterAssignedPhase        :{
        min: 1,
        max: 2,
        value: [1, 2]
      },
      filterHolidays        :{
        min: 0,
        max: 10,
        value: [0, 10]
      },
            
      filterRoles         : {
        selectValues      : [],
        selectSet         : new Set([])
      },
      
      filterUseDepartments  : {
        selectValues      : [],
        selectSet         : new Set([])
      },
      
      filterLocations  : {
        selectValues      : [],
        selectSet         : new Set([])
      },
      
      filterSchedules  : {
        selectValues      : [],
        selectSet         : new Set([])
      },
      
      filterCodeSchedules  : {
        selectValues      : [],
        selectSet         : new Set([])
      },
      
      filterCodeTypes  : {
        selectValues      : [],
        selectSet         : new Set([])
      },
      
      filterCodeSets  : {
        selectValues      : [],
        selectSet         : new Set([])
      },

      showAvailability    : false,
      showSteaksOnly      : false,
      hideLowMaxforStreaks : false,
      locationMisMatch     : false,
      errorMisMatch      : false,
      
      sortOptions:   ['user_id', 'email', 'assiged', 'assignedFiltered', 'quotum', 'performance', 'coverage'],
      sortOptionSelected: 'assignedFiltered',
      
      hoverBox             : null,
      
      // paginationSize       : 50,
      // curPage:              0
    }
  },

  async mounted(){
    this.companyId  = this.$route.params.companyId;
    this.jsonPath   = this.$route.params.jsonPath;
    this.runPath    = this.$route.params.runPath;
    this.file       = this.$route.params.file;
    this.getJSONdata()
  },

  methods: {
    async getJSONdata() {
      this.ShowPageLoading(15000)
      const jsonPath = encodeURIComponent(`${this.jsonPath}`)
      const runPath = encodeURIComponent(`${this.runPath}`)
      const fileName = encodeURIComponent(`${this.file}.json`)
      try {
        let response = await axios_api.get(`${API_ROOT}/maximus/company/${this.companyId}/buildRUN/viewOutput/${jsonPath}/run/${runPath}/${fileName}`);
        // let response = await axios_api.get(`${API_ROOT}/maximus/company/${this.companyId}/buildJSON/view/${jsonPath}/${fileName}`);
        this.json = response.data
      } catch (err) {
        this.json = {}
      }

      /*console.info(`done`)
      for(let key in this.json) {
        let data = JSON.stringify(this.json[key])
        console.info(`=>${key}: ${data.length}`)
      }*/
      this.preProcessJSON()
      
      this.getAllRoles(this.json.schedules)
      this.getAssignedPhaseValues(this.json.shifts)
      this.getQuotumValues(this.json.users)
      this.getHolidaysValues(this.json.users)
      this.getPerformanceValues(this.json.users)
      this.getCoverageValues(this.json.users)
      this.getUserDepartments(this.json.users)
      this.getLocations(this.json.inputJSON.locations)
      this.getSchedules(this.json.schedules)
      this.getCodeSchedules(this.json.codeSchedules)
      this.getCodeSets(this.json.inputJSON.code_sets, this.json.inputJSON.code_schedules)
      this.getCodeTypes(this.json.shifts)
      // console.info(`loaded completed`)
      
      this.filterjson()

      this.HidePageLoading()
    },
    preProcessJSON(){
      let u = {}
      for(let available of this.json.inputJSON.availability) {
        if (typeof(u[available.user_id]) === "undefined") {
          u[available.user_id] = []
        }
        u[available.user_id].push(available)
      }
      
      // console.info(`pre processing start:`, new Date())
      for(let user of this.json.users) {
        user.assigned = this.json.shifts.filter(s => s.assignedToUserId === user.user_id)
        user.availability = u[user.user_id] ? u[user.user_id] : [] 
        // if (user.user_id === 19076 ) {
        //   console.info(`user:`, user)
        //   console.info(`u[user.user_id]:`, user.assigned)
        //   console.info(`u[user.user_id]:`, user.availability)
        // }
      }
      
      let s = {}
      for(let shift of this.json.shifts) {
        shift.start = new Date(shift.startTimeObj)
        shift.end = new Date(shift.endTimeObj)
        // shift.isStreakpart = shift.isStreakpart ? shift.isStreakpart : 0
        s[shift.shiftId] = shift
      }
      this.json.s = s
      // console.info(`pre processing end:`, new Date())
    },
    getCodeTypes(shifts){
      let codeTypeSet = new Set([])
      let list = []
      for(let shift of shifts){
        if (!shift.type) continue
        if (codeTypeSet.has(shift.type)) continue
        
        codeTypeSet.add(shift.type)
        list.push({
          id: shift.type,
          name: shift.type,
          label: shift.type,
          value: true
        })
      }
      this.filterCodeTypes.selectValues = list
      this.filterCodeTypes.selectSet = this.getCodeTypeSelectedItems(this.filterCodeTypes.selectValues)
      // console.info(`this.filterCodeTypes:`, this.filterCodeTypes) 
    },
    getCodeSets(codeSets, codeSchedules){
      let list = []
      for(let codeSet of codeSets){
        let codeScheduleIdSet = new Set(codeSet.code_schedule_ids)
        list.push({
          id: codeSet.id,
          name: codeSet.name,
          code_schedule_ids: codeSet.code_schedule_ids,
          codeSchedules: codeSchedules.filter(c => codeScheduleIdSet.has(c.code_schedule_id)),
          label: codeSet.name,
          value: true
        })
      }
      this.filterCodeSets.selectValues = list
      this.filterCodeSets.selectSet = this.getCodeSetSelectedItems(this.filterCodeSets.selectValues)
      // console.info(`this.filterCodeSets:`, this.filterCodeSets) 
    },
    getCodeSetSelectedItems(codeSetValues){
      let list = new Set([])
      for(let codeSet of codeSetValues) {
        if (codeSet.value !== true) continue
        for(let code_schedule_id of codeSet.code_schedule_ids){
          list.add(code_schedule_id)  
        }
        
      }
      // console.info(`getCodeSetSelectedItems:`, [...list].join(`, `))
      return new Set([...list])
    },
    getCodeTypeSelectedItems(codeTypeValues){
      let list = new Set([])
      for(let codeType of codeTypeValues) {
        if (codeType.value !== true) continue
        
        list.add(codeType.name)       
        
      }
      // console.info(`getCodeTypeSelectedItems:`, [...list].join(`, `))
      return new Set([...list])
    },
    getUserDepartments(users){
      let departmentSet = new Set([])
      let list = []
      for(let user of users) {
        // console.info(`user:`, user)
        if (!user.departmentsList) continue        
        if (user.departmentsList.length <=0) continue
        for(let department of user.departmentsList) {
          if (departmentSet.has(department)) continue
          departmentSet.add(department)
          // console.info(`department:`, department)
          let findLocation = this.json.inputJSON.locations.find(l => l.id === department)
          if (!findLocation) continue
          // console.info(`findLocation:`, findLocation)
          
          list.push({
            id: findLocation.id,
            name: findLocation.name,
            prefix: findLocation.prefix ? findLocation.prefix : '',
            label: findLocation.is_head_office === 1 ? `${findLocation.id}-${findLocation.name}-(main)` : `${findLocation.id}-${findLocation.name}`,
            value: true,
          })
        }
      }    
      // console.info(`list:`, list)  
      this.filterUseDepartments.selectValues = list     
      this.filterUseDepartments.selectSet = this.getUserDepartmentsSelectedItems(this.filterUseDepartments.selectValues)      
      // console.info(`this.filterLocations:`, this.filterLocations)
    },
    getLocations(locations){
      this.filterLocations.selectValues = locations.map(location=>{
        return {
          id: location.id,
          name: location.name,
          prefix: location.prefix,
          label: location.is_head_office === 1 ? `${location.id}-${location.name}-(main)` : `${location.id}-${location.name}`,
          value: true,
        }
      })
      this.filterLocations.selectSet = this.getLocationSelectedItems(this.filterLocations.selectValues)      
      // console.info(`this.filterLocations:`, this.filterLocations)
    },
    getSchedules(schedules){
      this.filterSchedules.selectValues = schedules.map(s=>{
        return {
          id: s.scheduleId,
          name: s.scheduleName,
          label: `${s.scheduleId}-${s.scheduleName}`,
          accessRoles: s.accessRoles,
          value: true,
        }
      })
      this.filterSchedules.selectSet = this.getScheduleSelectedItems(this.filterSchedules.selectValues)      
      // console.info(`this.filterSchedules:`, this.filterSchedules)
    },
    getCodeSchedules(codeSchedules){
      let codeScheduleList = []
      let codeScheduleSet = new Set([])
      for(let codeSchedule of codeSchedules){
        if (codeScheduleSet.has(codeSchedule.codeCode)) continue
        codeScheduleSet.add(codeSchedule.codeCode)
        
        codeScheduleList.push({          
          code: codeSchedule.codeCode,
          start: codeSchedule.startTime,
          end: codeSchedule.endTime,
          shifts: codeSchedule.shifts,
          assigned: codeSchedule.assignedCount,
          label: `${codeSchedule.codeCode} ${codeSchedule.startTime} - ${codeSchedule.endTime}`,
          value: true
        })
      }
      
      codeScheduleList.sort((a,b) => { 
        if (a.code.toLowerCase() < b.code.toLowerCase()) return -1
        if (a.code.toLowerCase() < b.code.toLowerCase()) return 1
        return 0
      })
      
      this.filterCodeSchedules.selectValues = codeScheduleList
      this.filterCodeSchedules.selectSet = this.getCodeScheduleSelectedItems(this.filterCodeSchedules.selectValues)
    },
    getUserDepartmentsSelectedItems(departmentValues){
      let list = new Set([])
      for(let department of departmentValues) {
        if (department.value !== true) continue
        // console.info(`-->>>codeSchedule:`, codeSchedule)
        list.add(department.id)        
      }
      // console.info(`getCodeScheduleSelectedItems:`, [...list].join(`, `))
      return new Set([...list])
    },
    getLocationSelectedItems(locationValues){
      let list = new Set([])
      for(let location of locationValues) {
        if (location.value !== true) continue
        // console.info(`-->>>codeSchedule:`, codeSchedule)
        list.add(location.id)        
      }
      // console.info(`getCodeScheduleSelectedItems:`, [...list].join(`, `))
      return new Set([...list])
    },
    getScheduleSelectedItems(scheduleValues){
      let list = new Set([])
      for(let schedule of scheduleValues) {
        if (schedule.value !== true) continue
        // console.info(`-->>>codeSchedule:`, codeSchedule)
        list.add(schedule.id)        
      }
      // console.info(`getCodeScheduleSelectedItems:`, [...list].join(`, `))
      return new Set([...list])
    },
    getCodeScheduleSelectedItems(codeScheduleValues){
      let list = new Set([])
      for(let codeSchedule of codeScheduleValues) {
        if (codeSchedule.value !== true) continue
        // console.info(`-->>>codeSchedule:`, codeSchedule)
        list.add(codeSchedule.code.toLocaleLowerCase())        
      }
      // console.info(`getCodeScheduleSelectedItems:`, [...list].join(`, `))
      return new Set([...list])
    },
    getAssignedPhaseValues(shifts){
      let min = 1
      let max = 2
      for(let shift of shifts) {
        if (shift.assignedPhaseId === null) continue
        min = Math.min(min, shift.assignedPhaseId)
        max = Math.max(max, shift.assignedPhaseId)
      }
      max = Math.min(max, 10) //cap at 10
      this.filterAssignedPhase = {
        min, 
        max, 
        value: [min, max]
      }
      // console.info(`this.filterQuotum:`, this.filterQuotum)
    },    
    getQuotumValues(users){
      let min = 0
      let max = 1
      for(let user of users) {
        if (user.min !== null) min = Math.min(min, user.min)
        if (user.max !== null) max = Math.max(max, user.max)
      }
      max = Math.min(max, 100) //cap at 100
      this.filterQuotum = {
        min, 
        max, 
        value: [0, max]
      }
      // console.info(`this.filterQuotum:`, this.filterQuotum)
    },    
    getPerformanceValues(users){
      let min = 0
      let max = 1
      for(let user of users) {
        if (user.postPerformance !== null) min = Math.min(min, user.postPerformance)
        if (user.postPerformance !== null) max = Math.max(max, user.postPerformance)
      }
      max = Math.min(max, 2) //cap at 2
      this.filterPerformance = {
        min, 
        max, 
        value: [0, max]
      }
      // console.info(`this.getPerformanceValues:`, this.filterPerformance)
    },    
    getHolidaysValues(users){
      let min = 0
      let max = 1
      for(let user of users) {
        let holidaysAssigned = user.assigned.filter(s => s.isHoliday === 1).length
        if (holidaysAssigned !== null) min = Math.min(min, holidaysAssigned)
        if (holidaysAssigned !== null) max = Math.max(max, holidaysAssigned)
      }
      max = Math.min(max, 10) //cap at 1
      this.filterHolidays = {
        min, 
        max, 
        value: [0, max]
      }
      // console.info(`this.getCoverageValues:`, this.filterCoverage)
    },    
    getCoverageValues(users){
      let min = 0
      let max = 1
      for(let user of users) {
        if (user.postCoverage !== null) min = Math.min(min, user.postCoverage)
        if (user.postCoverage !== null) max = Math.max(max, user.postCoverage)
      }
      max = Math.min(max, 1) //cap at 1
      this.filterCoverage = {
        min, 
        max, 
        value: [0, max]
      }
      // console.info(`this.getCoverageValues:`, this.filterCoverage)
    },    
    getAllRoles(schedules){
      let roleSet = new Set([])
      for(let schedule of schedules) {
        if (!schedule.accessRoles || schedule.accessRoles.length <=0) continue
        for(let role of schedule.accessRoles){
          roleSet.add(role.name)
        }
      }
      // console.info(`roles:`, roleSet)
      // return
      let rolesArray = [...roleSet]
      rolesArray.sort((a,b) => { 
        if (a.toLowerCase() < b.toLowerCase()) return -1
        if (a.toLowerCase() < b.toLowerCase()) return 1
        return 0
      })
      
      this.filterRoles.selectValues = this.arrayToBoleanObjArray(rolesArray)
      this.filterRoles.selectSet = this.getArrayTrueValueSet(this.filterRoles.selectValues)
      // console.info(`this.filterRoles:`, this.filterRoles)
    },
    updateSelectedRoles(){
      this.filterRoles.selectSet = this.getArrayTrueValueSet(this.filterRoles.selectValues)
    },
    updateSelectedLocations(){
      this.filterLocations.selectSet = this.getLocationSelectedItems(this.filterLocations.selectValues)
      // console.info(`this.filterLocations.selectSet:`, this.filterLocations.selectSet)
    },
    updateSelectedUserDepartments(){
      this.filterUseDepartments.selectSet = this.getUserDepartmentsSelectedItems(this.filterUseDepartments.selectValues)
      // console.info(`this.filterLocations.selectSet:`, this.filterLocations.selectSet)
    },
    updateSelectedSchedules(){
      this.filterSchedules.selectSet = this.getScheduleSelectedItems(this.filterSchedules.selectValues)
      // console.info(`this.filterSchedules.selectSet:`, this.filterSchedules.selectSet)
    },
    updateSelectedCodeSchedules(){
      this.filterCodeSchedules.selectSet = this.getCodeScheduleSelectedItems(this.filterCodeSchedules.selectValues)
      // console.info(`this.filterCodeSchedules.selectSet:`, this.filterCodeSchedules.selectSet)
    },
    updateSelectedCodeTypes(){
      this.filterCodeTypes.selectSet = this.getCodeTypeSelectedItems(this.filterCodeTypes.selectValues)
      // console.info(`==>this.updateSelectedCodeTypes.selectSet:`, this.filterCodeSets.selectSet)
    },
    updateSelectedCodeSets(){
      this.filterCodeSets.selectSet = this.getCodeSetSelectedItems(this.filterCodeSets.selectValues)
      // console.info(`==>this.updateSelectedCodeTypes.selectSet:`, this.filterCodeSets.selectSet)
    },
    updateFilters(){
      this.filterjson()
    },
    updateFilterQuotum(newVal){
      this.filterQuotum.value = newVal
    },
    updateFilterPerformance(newVal){
      this.filterPerformance.value = newVal
    },
    updateFilterCoverage(newVal){
      this.filterCoverage.value = newVal
    },
    updateFilterAssigned(newVal){
      this.filterAssigned.value = newVal
    },
    updateFilterAssignedPhase(newVal){
      this.filterAssignedPhase.value = newVal
    },
    updateFilterHolidays(newVal){
      this.filterHolidays.value = newVal
    },
    updateFilterAvailable(newVal){
      this.filterAvailable.value = newVal
    },
    updateSearch(val){
      this.filterSearch = val
    },
    getArrayTrueValueSet(obj) {
      let list = new Set([])
      for(let item of obj) {
        if (item.value !== true) continue
        list.add(item.label.toLowerCase())
      }
      // console.info(`getArrayTrueValueSet:`, list)
      return list
    },
    arrayToBoleanObjArray(array) {
      let list = []
      for(let item of array) {
        list.push({
          label: item,
          value: true
        })
      }
      return list
    },
    hasUserMustHaveRole(userRoles){
      if (this.filterRoles.selectSet.size === this.filterRoles.selectValues.length) return true //show all if no filter set!
      for(let userRole of userRoles) {
        // console.info(`user role:`, userRole)
        if (this.filterRoles.selectSet.has(userRole.toLowerCase())) return true
      }
      return false
    },
    hasSearchMatch(user){
      let search = this.filterSearch.toLocaleLowerCase().trim()
      // console.info(`search:`, search)
      if (search === '') return true
      
      // let roleStr = user.rolesList && user.rolesList.length > 0 ? user.rolesList.join(`|`) : 'no-role'
      // let userSearchString = `${user.user_id}|${user.company_user_id}|${user.email}|${roleStr}|${user.mobile}`.toLocaleLowerCase()
      let userSearchString = `${user.email}`.toLocaleLowerCase()
      
      search = search.split(`\n`).join(` `)
      search = search.split(`\r`).join(` `)
      search = search.split(`\t`).join(` `)
      search = search.split(`,`).join(` `)
      let items = search.split(` `)
      for(let item of items) {
        item = item.trim()
        if (item === '') continue
        if (parseInt(item) === user.user_id) return true
        if (parseInt(item) === user.company_user_id) return true
        if (userSearchString.indexOf(item) > -1) return true
      }
      
      return false      
    },
    hasQuotumMatch(user) {
      let userQuotum = user.quotum ? parseFloat(user.quotum) : 0
      if (userQuotum < parseInt(this.filterQuotum.value[0])) return false
      if (this.filterQuotum.value[1] < this.filterQuotum.max && userQuotum > parseInt(this.filterQuotum.value[1])) return false
      return true
    },
    hasPerformanceMatch(user) {
      let userPerformance = user.postPerformance ? parseFloat(user.postPerformance) : 0
      if (userPerformance < parseFloat(this.filterPerformance.value[0])) return false
      if (this.filterPerformance.value[1] < this.filterPerformance.max && userPerformance > parseFloat(this.filterPerformance.value[1])) return false
      return true
    },
    hasUserLocation(user) {
      // console.info(`user:`, user.departmentsList)
      let selectedDepartmentsSet = this.getUserDepartmentsSelectedItems(this.filterUseDepartments.selectValues)
      if (selectedDepartmentsSet.size === this.filterUseDepartments.selectValues.length) return true
      
      if (!user.departmentsList) return false
      if (user.departmentsList.length <=0) return false
      for(let department of user.departmentsList){
        if (selectedDepartmentsSet.has(department)) return true
      }
      return false
    },
    hasCoverageMatch(user) {
      let userCoverage = user.postCoverage ? parseFloat(user.postCoverage) : 0
      if (userCoverage < parseFloat(this.filterCoverage.value[0])) return false
      if (this.filterCoverage.value[1] < this.filterCoverage.max && userCoverage > parseFloat(this.filterCoverage.value[1])) return false
      return true
    },
    hasErrorMatch(user) {
      if (this.errorMisMatch === false) return true
      return user.hasIssue
    },
    hasAvailableMatch(user) {
      if (!this.showAvailability) return true
      let userAvailablity = user.availabilityFiltered ? user.availabilityFiltered.length : 0
      if (userAvailablity < parseInt(this.filterAvailable.value[0])) return false
      if (this.filterAvailable.value[1] < this.filterAvailable.max && userAvailablity > parseInt(this.filterAvailable.value[1])) return false
      return true
    },
    hasAssignedMatch(user) {
      let userAssigned = user.assignedFiltered ? user.assignedFiltered.length : 0
      if (userAssigned < parseInt(this.filterAssigned.value[0])) return false
      if (this.filterAssigned.value[1] < this.filterAssigned.max && userAssigned > parseInt(this.filterAssigned.value[1])) return false
      return true
    },
    hasStreakLowMaxAvailability(user){
      if (!this.showSteaksOnly) return true
      if (!this.hideLowMaxforStreaks) return true
      /* eslint-disable */
      // console.info(`user:`, user)
      let userMaxWeek = user.max_per_week || 0
      // console.info(`userMaxWeek:`, userMaxWeek)
      let userMaxMonth = user.max_per_month || 0
      // console.info(`userMaxMonth:`, userMaxMonth)      
      
      let userAssignedStreaks = user.assigned.filter(s => s.isStreakpart === 1)
      if (userAssignedStreaks.length > 0) return true
      
      let userAvailability = user.availabilityFiltered.length || 0
      
      let minStreakLen = 99
      for(let streak of this.json.streaks) {
        minStreakLen = Math.min(minStreakLen, streak.min_streak)
      }
      // console.info(`minStreakLen:`, minStreakLen)
      if (userMaxWeek < minStreakLen) return false
      if (userMaxMonth < minStreakLen) return false
      if (userAvailability < minStreakLen) return false
      return true
    },
    hasAssignedHolidaysMatch(user) {
      let holidaysAssigned = (user.assigned.filter(s => s.isHoliday === 1)).length
      
      if (holidaysAssigned < parseInt(this.filterHolidays.value[0])) return false
      if (this.filterHolidays.value[1] < this.filterHolidays.max && holidaysAssigned > parseInt(this.filterHolidays.value[1])) return false
      return true
    },
    hasStreakPart(shift, availability) {
      if (this.showSteaksOnly === false) return true
      if (!shift.isStreakpart) return false
      if (availability.exempt_from_streak === 1) return false
      return shift.isStreakpart === 1
    },
    hasAssignedPhaseMatch(shift) {
      let phase = shift.assignedPhaseId === null ? 0 : shift.assignedPhaseId
      if (phase < parseInt(this.filterAssignedPhase.value[0])) return false
      if (this.filterAssignedPhase.value[1] < this.filterAssignedPhase.max && phase > parseInt(this.filterAssignedPhase.value[1])) return false
      return true
    },
    sortUserList(userList){
      if (this.sortOptionSelected === 'assignedFiltered') {
        userList.sort((a,b) => {
          if (b.assignedFiltered.length === a.assignedFiltered.length) {
            return b.availabilityFiltered.length - a.availabilityFiltered.length
          }
          return b.assignedFiltered.length - a.assignedFiltered.length
        })
      } else if (this.sortOptionSelected === 'assiged') {
        userList.sort((a,b) => {
          if (b.assigned.length === a.assigned.length) {
            return b.availability.length - a.availability.length
          }
          return b.assigned.length - a.assigned.length
        })
      } else if (this.sortOptionSelected === 'user_id') {
        userList.sort((a,b) => {
          return a.user_id - b.user_id
        })
      } else if (this.sortOptionSelected === 'email') {
        userList.sort((a,b) => {
          if (b.email.toLowerCase() < a.email.toLowerCase()) return 1
          if (b.email.toLowerCase() > a.email.toLowerCase()) return -1
          return 0
        })        
      } else if (this.sortOptionSelected === 'quotum') {
        userList.sort((a,b) => {
          return a.quotum - b.quotum
        })
      } else if (this.sortOptionSelected === 'performance') {
        userList.sort((a,b) => {
          return b.postPerformance - a.postPerformance
        })
      } else if (this.sortOptionSelected === 'coverage') {
        userList.sort((a,b) => {
          return b.postCoverage - a.postCoverage
        })
      }
      return userList
    },
    hasUserErrors(user) {
      if (user.errMax.length > 0) return true
      if (user.errCodeSetMax.length > 0) return true
      if (user.errCodeMax.length > 0) return true
      if (user.errMaxPerWeek.length > 0) return true
      if (user.errMaxPerMonth.length > 0) return true
      if (user.errMaxHolidays.length > 0) return true
      if (user.errTimeBetweenShifts.length > 0) return true
      if (user.errOther.length > 0) return true
      return false
    },    
    isDepartMentMisMatch(locationId, userLocationSet) {
      if (locationId === null) return false
      if (userLocationSet.size === 0) return false
      return !userLocationSet.has(locationId)
    },
    filterjson(){
      let rowWrap = document.createElement("div")
      rowWrap.className = 'rowsWrapper'
      rowWrap.innerHTML = ''
      

      const wWidth = window.innerWidth
      const calWith = Math.floor(wWidth * 0.9)
      const minDate = new Date(this.json.minDate)
      const maxDate = new Date(this.json.maxDate)
      const start = minDate.getTime()
      const range = (maxDate.getTime() - minDate.getTime())
      // console.info(`range:`, range)

      // let s = {}
      // for(let shift of this.json.shifts) {
      //   shift.start = new Date(shift.startTimeObj)
      //   shift.end = new Date(shift.endTimeObj)
      //   s[shift.shiftId] = shift
      // }
      const s = this.json.s
      // console.info(`this.filterCodeSchedules:`, this.filterCodeSchedules)
      // console.info(`this.filterCodeSets:`, this.filterCodeSets)
      // https://www.codegrepper.com/code-examples/javascript/frameworks/build/eslint-disable-next-line+no-case-declarations+disable+rule
      /* eslint-disable */
      // return
      
      let userList = []
      for(let user of this.json.users) {
        if (!this.hasUserMustHaveRole(user.rolesList)) continue
        if (!this.hasSearchMatch(user)) continue
        if (!this.hasQuotumMatch(user)) continue
        if (!this.hasPerformanceMatch(user)) continue
        if (!this.hasCoverageMatch(user)) continue
        if (this.errorMisMatch && !this.hasUserErrors(user)) continue
        
        if (!this.hasUserLocation(user)) continue
        
        
        
        // continue
        
        //user.assigned = this.json.shifts.filter(s => s.assignedToUserId === user.user_id)
        
        user.assignedFiltered = user.assigned.filter(s => 
          this.filterCodeSchedules.selectSet.has(s.codeCode.toLocaleLowerCase()) &&
          this.filterLocations.selectSet.has(s.departmentId) && 
          this.filterSchedules.selectSet.has(s.scheduleId) && 
          this.filterCodeSets.selectSet.has(s.codeScheduleId) &&          
          this.filterCodeTypes.selectSet.has(s.type) &&          
          (this.showSteaksOnly === false || s.isStreakpart === 1) &&
          this.hasAssignedPhaseMatch(s) &&
          (this.locationMisMatch === false || this.isDepartMentMisMatch(s.departmentId, new Set(user.departmentsList)))
        )
        
        //user.availability = this.json.inputJSON.availability.filter(s => s.user_id === user.user_id)
        user.availabilityFiltered = this.showAvailability ? user.availability.filter(a => {
          
          return this.filterCodeSets.selectSet.has(s[a.shift_id].codeScheduleId) &&
          this.filterCodeTypes.selectSet.has(s[a.shift_id].type) && 
          this.filterLocations.selectSet.has(s[a.shift_id].departmentId) && 
          this.filterSchedules.selectSet.has(s[a.shift_id].scheduleId) && 
          this.filterCodeSchedules.selectSet.has(s[a.shift_id].codeCode.toLocaleLowerCase()) &&
          this.hasStreakPart(s[a.shift_id], a) &&          
          // (this.showSteaksOnly === false || (s[a.shift_id].isStreakpart == 1 && a.exempt_from_streak === 0))
          (this.locationMisMatch === false || this.isDepartMentMisMatch(s[a.shift_id].departmentId, new Set(user.departmentsList)))
        }) : []
        
        if (!this.hasAvailableMatch(user)) continue       
        if (!this.hasAssignedMatch(user)) continue    
        if (!this.hasAssignedHolidaysMatch(user)) continue    
        if (!this.hasStreakLowMaxAvailability(user)) continue    
        
        
        userList.push(user)
        
      }
      // console.info(`userList:`, userList)
      
      userList = this.sortUserList(userList)
      

      let count = 0
      for(let user of userList) {   
        // continue 
        // const minUserCount = this.paginationSize * this.curPage
        // const maxMaxUserCount = minUserCount + this.paginationSize -1   
        // if (count < minUserCount) {
        //   count += 1
        //   continue
        // }
        // if (count > maxMaxUserCount) continue
        
        
        let createUserRow = document.createElement("div")
        createUserRow.className = 'userRow'

        let userDetailsWrap = document.createElement("div")
        userDetailsWrap.className = this.hasUserErrors(user) ? 'userDetails hasError': 'userDetails'
        // console.info(`user:`, user)
        // userDetailsWrap.innerText = `id:${user.user_id} q:${user.quotum}, p:${roundMaxDigits(user.postPerformance, 2)}, c:${roundMaxDigits(user.postCoverage,2)} ${user.email}`
        // userDetailsWrap.innerHTML = `<a href='https://waarneemapp.nl/companies/${this.companyId}/users/${user.company_user_id}/profile/${user.user_id}' target='_blank'>${user.user_id}-${user.email}-(q:${user.quotum}) -> ${user.assignedFiltered.length}</a>`
        userDetailsWrap.innerText = `${user.user_id} - ${user.email} - (q:${user.quotum}) -> ${user.assignedFiltered.length}`
        userDetailsWrap.user = user
        userDetailsWrap.onmouseenter = this.showUserDetails
        userDetailsWrap.onmouseout = this.hideDetails
        createUserRow.appendChild(userDetailsWrap)

        let userCalGrid = document.createElement("div")
        userCalGrid.className = 'userCalGrid'
        createUserRow.appendChild(userCalGrid)

        let userCalLine = document.createElement("div")
        userCalLine.className = 'userCalLine'
        userCalGrid.appendChild(userCalLine)
        
        // let userShifts = this.json.shifts.filter(s => s.assignedToUserId === user.user_id)
        // console.info(`userShifts ${user.user_id}-${user.email}:`, userShifts.length)
        for(let userShift of user.assignedFiltered) {
          let userShiftDot = document.createElement("div")
          userShiftDot.user = user
          userShiftDot.shift = userShift
          let shiftStyles = ['userShift']
          if (userShift.isNight === 1) shiftStyles.push('nightShift')
          if (userShift.isHoliday === 1) shiftStyles.push('holidayShift')
          if (userShift.type === 'reserve') shiftStyles.push('reserveShift')
          if (userShift.isStreakpart === 1) shiftStyles.push('streakShift')
          let errors = this.getShiftErrors(userShift)
          if (errors.length > 0) shiftStyles.push('shiftError')
          userShiftDot.className = shiftStyles.join(` `)
          userShiftDot.style.left = `${(userShift.start.getTime() - start) / range * 100}%`
          userShiftDot.style.width = `${Math.min(2, (userShift.end.getTime() - userShift.start.getTime()) / range * calWith) * 4}px`
          userShiftDot.onmouseenter = this.showShiftDetails
          userShiftDot.onmouseout = this.hideDetails
          userCalGrid.appendChild(userShiftDot)
        }


        // let userAvailability = this.showAvailability ? this.json.inputJSON.availability.filter(a => 
        //   a.user_id === user.user_id &&
        //   this.filterCodeSets.selectSet.has(s[a.shift_id].codeScheduleId) &&
        //   this.filterCodeSchedules.selectSet.has(s[a.shift_id].codeCode.toLocaleLowerCase()) && 
        //   (this.showSteaksOnly === false || s[a.shift_id].isStreakpart === 1)
        // ) : []
        // console.info(`userAvailability ${user.user_id}-${user.email}:`, userAvailability.length)
        for(let userAvailable of user.availabilityFiltered) {
          let userAvailableDot = document.createElement("div")
          let availableShift = s[userAvailable.shift_id]
          userAvailableDot.shift = availableShift
          let availableStyles = ['userAvailable']
          if (availableShift.isNight === 1) availableStyles.push('nightShift')
          if (availableShift.isHoliday === 1) availableStyles.push('holidayShift')
          if (availableShift.type === 'reserve') availableStyles.push('reserveShift')
          userAvailableDot.className = availableStyles.join(` `)
          userAvailableDot.style.left = `${(availableShift.start.getTime() - start) / range * 100}%`
          userAvailableDot.style.width = `${Math.min(2, (availableShift.end.getTime() - availableShift.start.getTime()) / range * calWith) * 2}px`
          userCalGrid.appendChild(userAvailableDot)
        }



        rowWrap.appendChild(createUserRow)
        count +=1
        // console.info(`count:`, count)
        // if (count > 50) break
      }

      let hoverBoxWrap = document.createElement("div")
      hoverBoxWrap.className = 'hoverBox HideDiv'
      // hoverBoxWrap.innerText = 'DIT IS EEN TEST'
      this.hoverBox = hoverBoxWrap
      let mainWrap = document.getElementById('UserOverviewWrap')
      mainWrap.innerHTML = ''
      mainWrap.appendChild(rowWrap)
      mainWrap.appendChild(this.hoverBox)
      // this.jsonFiltered = this.json
    },
    
    
    getRolesFilterLabel(){
      if (this.filterRoles.selectSet.size === this.filterRoles.selectValues.length) return 'Roles'
      return [...this.filterRoles.selectSet].join(`, `)
    },    
    getUserDepartmentsFilterLabel(){
      if (this.filterUseDepartments.selectSet.size === this.filterUseDepartments.selectValues.length) return 'User Departments'
      return `User Departments: ${[...this.filterUseDepartments.selectSet].join(`, `)}`
    },
    getLocationFilterLabel(){
      if (this.filterLocations.selectSet.size === this.filterLocations.selectValues.length) return 'Shift Locations'
      return `Shift Locations: ${[...this.filterLocations.selectSet].join(`, `)}`
    },
    getScheduleFilterLabel(){
      if (this.filterSchedules.selectSet.size === this.filterSchedules.selectValues.length) return 'Schedules'
      return [...this.filterSchedules.selectSet].join(`, `)
    },
    getCodeScheduleFilterLabel(){
      if (this.filterCodeSchedules.selectSet.size === this.filterCodeSchedules.selectValues.length) return 'CodeSchedules'
      return [...this.filterCodeSchedules.selectSet].join(`, `)
    },
    getCodeTypeFilterLabel(){
      let isActive = this.filterCodeTypes.selectValues.find(c => c.value === false)
      if (!isActive) return 'Type'
      let list = this.filterCodeTypes.selectValues.filter(c => c.value === true).map(c => c.name)
      return list.join(`, `)
    },
    getCodeSetsFilterLabel(){
      let isActive = this.filterCodeSets.selectValues.find(c => c.value === false)
      if (!isActive) return 'CodeSets'
      let list = this.filterCodeSets.selectValues.filter(c => c.value === true).map(c => c.name)
      return list.join(`, `)
    },
    getQuotumFilterLabel(){
      if (this.filterQuotum.min === this.filterQuotum.value[0] && this.filterQuotum.max === this.filterQuotum.value[1]) return 'Quotum'
      return `Quotum: ${this.filterQuotum.value[0]} - ${this.filterQuotum.value[1]}`
    },
    getPerformanceFilterLabel(){
      if (this.filterPerformance.min === this.filterPerformance.value[0] && this.filterPerformance.max === this.filterPerformance.value[1]) return 'Performance'
      return `Performance: ${this.filterPerformance.value[0]} - ${this.filterPerformance.value[1]}`
    },
    getCoverageFilterLabel(){
      if (this.filterCoverage.min === this.filterCoverage.value[0] && this.filterCoverage.max === this.filterCoverage.value[1]) return 'Coverage'
      return `Coverage: ${this.filterCoverage.value[0]} - ${this.filterCoverage.value[1]}`
    },
    getAssignedFilterLabel(){
      if (this.filterAssigned.min === this.filterAssigned.value[0] && this.filterAssigned.max === this.filterAssigned.value[1]) return 'Assigned'
      return `Assigned: ${this.filterAssigned.value[0]} - ${this.filterAssigned.value[1]}`
    },
    getAssignedPhaseFilterLabel(){
      if (this.filterAssignedPhase.min === this.filterAssignedPhase.value[0] && this.filterAssignedPhase.max === this.filterAssignedPhase.value[1]) return 'Assigned Phase'
      return `Phase: ${this.filterAssignedPhase.value[0]} - ${this.filterAssignedPhase.value[1]}`
    },
    getHolidaysFilterLabel(){
      if (this.filterHolidays.min === this.filterHolidays.value[0] && this.filterHolidays.max === this.filterHolidays.value[1]) return 'Holidays'
      return `Holidays: ${this.filterHolidays.value[0]} - ${this.filterHolidays.value[1]}`
    },
    getAvailableFilterLabel(){
      if (this.filterAvailable.min === this.filterAvailable.value[0] && this.filterAvailable.max === this.filterAvailable.value[1]) return 'Availablity'
      return `Availablity: ${this.filterAvailable.value[0]} - ${this.filterAvailable.value[1]}`
    },
    hasValue(val) {
      if (typeof(val) === "undefined") return false
      if (val === null) return false
      return true
    },     
    showUserDetails(evt){
      // console.info(`evt:`, evt.srcElement.shift.shiftId)
      // console.info(`evt:`, `shift-${shift.shiftId}`)
      let user = evt.srcElement.user
      if (!user) this.hideDetails()  
      this.showDetails(user, null, evt)
    },
    showShiftDetails(evt){
      // console.info(`evt:`, evt.srcElement.shift.shiftId)
      // console.info(`evt:`, `shift-${shift.shiftId}`)
      let shift = evt.srcElement.shift
      let user = evt.srcElement.user
      if (!shift) this.hideDetails()  
      this.showDetails(user, shift, evt)
    },
    getShiftErrors(shift){
      if (!shift.errors) return []
      if (shift.errors.length <= 0) return []
      let list = []
      for(let err of shift.errors) {
        if (err.indexOf(`shift user department`) > -1 && err.indexOf(`mismatch`) > -1) continue
        list.push(err)
      }
      return list
    },
    showDetails(user, shift, evt){
      
      let t = []
      if (shift) {
        t.push(`${shift.date} ${shift.startTime}-${shift.endTime}`)
        t.push(`${shift.scheduleName}`)
        t.push(`${shift.codeCode} - ${shift.codeName} - ${shift.type}`)      
        t.push(`<strong>ShiftId</strong>: ${shift.shiftId}`)      
        t.push(`<strong>VancacyId</strong>: ${shift.vacancyId}`)      
        t.push(`<strong>DepartmentId</strong>:`, shift.departmentId ? shift.departmentId : 'NULL')
        if (shift.isHoliday) t.push(`<strong>Holiday</strong>:`, shift.holidayName)
        
      }
      t.push(`<strong>UserId</strong>: ${user.user_id}`)
      t.push(`<strong>Email</strong>: ${user.email}`)
      t.push(`<strong>Departments</strong>: ${user.departmentsList.join(`,`)}`)
      t.push(`<strong>Roles</strong>: ${user.rolesList.join(`, `)}`)
      t.push(`<strong>Quotum</strong>: ${user.quotum}`)
      t.push(`<strong>Performance</strong>: ${ roundMaxDigits(user.postPerformance, 2)}`)
      t.push(`<strong>PostCoverage</strong>: ${ roundMaxDigits(user.postCoverage, 2)}`)
        
      const userAvailability = this.json.inputJSON.availability.filter(a => a.user_id === user.user_id)
      const availableSet = new Set(userAvailability.map(a => a.shift_id))
      const userAssigned = this.json.shifts.filter(s => s.assignedToUserId === user.user_id)
        
      t.push(`<br>Main:`)
      let userMinMaxPref = this.json.inputJSON.min_max_hours.find(u => u.user_id === user.user_id) || {}
      if (this.hasValue(userMinMaxPref.min_hours)) t.push(`<strong>min hours</strong>: ${userMinMaxPref.min_hours}`)
      if (this.hasValue(userMinMaxPref.max_hours)) t.push(`<strong>max hours</strong>: ${userMinMaxPref.max_hours}`)
      if (this.hasValue(userMinMaxPref.min_holidays)) t.push(`<strong>min holidays</strong>: ${userMinMaxPref.min_holidays}`)
      if (this.hasValue(userMinMaxPref.max_holidays)) t.push(`<strong>max holidays</strong>: ${userMinMaxPref.max_holidays}`)
      
      let userPref = this.json.inputJSON.users.find(u => u.user_id === user.user_id) || {}
      if (this.hasValue(userPref.min_per_week)) t.push(`<strong>min per week</strong>: ${userPref.min_per_week}`)
      if (this.hasValue(userPref.max_per_week)) t.push(`<strong>max per week</strong>: ${userPref.max_per_week}`)
      if (this.hasValue(userPref.min_per_month)) t.push(`<strong>min per month</strong>: ${userPref.min_per_month}`)
      if (this.hasValue(userPref.max_per_month)) t.push(`<strong>max per month</strong>: ${userPref.max_per_month}`)
      
      t.push(`<br><strong>total Available</strong>: ${userAvailability.length || 0}`)
      t.push(`<strong>total Assigned</strong>: ${userAssigned.length || 0}`)
        
        
      if (userMinMaxPref.code_preferences.find(c => typeof(c.code_schedule_ids) !== "undefined")) t.push(`<br>CodeSets:`)
      for(let code_preference of userMinMaxPref.code_preferences){
        if (typeof(code_preference.code_schedule_ids) === "undefined") continue
        let list = [`<strong>${code_preference.name}</strong>:`]
        
        if (this.hasValue(code_preference.min_shifts)) list.push(`min shifts: ${code_preference.min_shifts}`)
        if (this.hasValue(code_preference.max_shifts)) list.push(`max shifts: ${code_preference.max_shifts}`)
        if (this.hasValue(code_preference.ignore_available) && code_preference.ignore_available === 1) list.push(`ignore_available: ${code_preference.ignore_available}`)
        
        let availableShifts = this.json.shifts.filter(s => availableSet.has(s.shiftId) && new Set(code_preference.code_schedule_ids).has(s.codeScheduleId))
        let AssignedShifts = userAssigned.filter(s => new Set(code_preference.code_schedule_ids).has(s.codeScheduleId))
        list.push(` -> ${availableShifts.length} / ${AssignedShifts.length}`)
        t.push(list.join(` - `))
      }
        
      if (userMinMaxPref.code_preferences.find(c => typeof(c.code_schedule_ids) === "undefined")) t.push(`<br>Codes:`)
      for(let code_preference of userMinMaxPref.code_preferences){
        if (typeof(code_preference.code_schedule_ids) !== "undefined") continue
        let findCode = this.json.codeSchedules.find(c => c.codeScheduleId === code_preference.code_schedule_id) || {}
        let list = [`<strong>${findCode.codeCode}</strong>:`]
        if (this.hasValue(code_preference.min_shifts)) list.push(`min shifts: ${code_preference.min_shifts}`)
        if (this.hasValue(code_preference.max_shifts)) list.push(`max shifts: ${code_preference.max_shifts}`)
        if (this.hasValue(code_preference.ignore_available) && code_preference.ignore_available === 1) list.push(`ignore_available: ${code_preference.ignore_available}`)
        
        let availableShifts = this.json.shifts.filter(s => availableSet.has(s.shiftId) && code_preference.code_schedule_id === s.codeScheduleId)
        let AssignedShifts = userAssigned.filter(s => code_preference.code_schedule_id === s.codeScheduleId)
        list.push(` -> ${availableShifts.length} / ${AssignedShifts.length}`)
        t.push(list.join(` - `))
      }
        
      if (userMinMaxPref.schedule_preferences) {
        t.push(`<br>Schedules:`)
        for(let schedule_preference of userMinMaxPref.schedule_preferences){
          // console.info(`schedule_preference:`, schedule_preference)
          let schedule = this.json.inputJSON.schedules.find(s => s.id === schedule_preference.schedule_id) || {}
          // console.info(`schedule:`, schedule)
          let list = [`<strong>${schedule.id}-${schedule.name}</strong>:`]
          if (this.hasValue(schedule_preference.min_shifts)) list.push(`min shifts: ${schedule_preference.min_shifts}`)
          if (this.hasValue(schedule_preference.max_shifts)) list.push(`max shifts: ${schedule_preference.max_shifts}`)
          if (this.hasValue(schedule_preference.min_hours)) list.push(`min hours: ${schedule_preference.min_hours}`)
          if (this.hasValue(schedule_preference.max_hours)) list.push(`max hours: ${schedule_preference.max_hours}`)
          
          let availableShifts = this.json.shifts.filter(s => availableSet.has(s.shiftId) && schedule_preference.schedule_id === s.scheduleId)
          let AssignedShifts = userAssigned.filter(s => schedule_preference.schedule_id === s.scheduleId)
          list.push(` -> ${availableShifts.length} / ${AssignedShifts.length}`)
          t.push(list.join(` - `))
        }
      }
        
      t.push(`<br>Schedules2:`)
      for(let schedule of this.json.inputJSON.schedules){
        let list = [`<strong>${schedule.id}-${schedule.name}</strong>:`]
        let availableShifts = this.json.shifts.filter(s => availableSet.has(s.shiftId) && schedule.id === s.scheduleId)
        let AssignedShifts = userAssigned.filter(s => schedule.id === s.scheduleId)
        list.push(` -> ${availableShifts.length} / ${AssignedShifts.length}`)
        t.push(list.join(` - `))
      }
      
      // console.info(`shift:`, shift)
      let errors = shift ? this.getShiftErrors(shift) : []
      if (errors.length > 0) {
        t.push(`<br>Shift Errors:`)
        if (errors.length > 0) t.push(`errors:`, errors.join(`, `))
      }
      
      if (this.hasUserErrors(user)) {
        t.push(`<br>Errors:`)
        // t.push(`<div class='userErrors>`)
        // console.info(`user:`, user)
        if (user.errMax.length > 0) t.push(`ErrMax:`, user.errMax.join(`, `))
        // if (user.errDepartment.length > 0) t.push(`  ErrDepartment: ${user.errDepartment.join(`, `)}`)
        if (user.errCodeSetMax.length > 0) t.push(`  errCodeSetMax: ${user.errCodeSetMax.join(`, `)}`)
        if (user.errCodeMax.length > 0) t.push(`  errCodeMax:  ${user.errCodeMax.join(`, `)}`)
        if (user.errMaxPerWeek.length > 0) t.push(`  errMaxPerWeek: ${user.errMaxPerWeek.join(`, `)}`)
        if (user.errMaxPerMonth.length > 0) t.push(`  errMaxPerMonth: ${user.errMaxPerMonth.join(`, `)}`)
        if (user.errMaxHolidays.length > 0) t.push(`  errCodeSetMax: ${user.errMaxHolidays.join(`, `)}`)
        if (user.errTimeBetweenShifts.length > 0) t.push(`  errCodeSetMax: ${user.errTimeBetweenShifts.join(`, `)}`)
        if (user.errOther.length > 0) t.push(`  errCodeSetMax: ${user.errOther.join(`, `)}`)
        // t.push(`</div>`)
        // console.info(`t:`, t)
      }
      
      
        
      this.hoverBox.innerHTML = t.join(`<br>`)
      this.hoverBox.className = 'hoverBox'
      let OffsetTop = this.calcPageOffsetPosY(evt.srcElement)
      let OffsetLeft = this.calcPageOffsetPosX(evt.srcElement)
      if (OffsetLeft > window.innerWidth * 0.6) {
        OffsetLeft = Math.max(0, OffsetLeft - 500)
      }
      this.hoverBox.style.top   = `${OffsetTop + 20}px`
      this.hoverBox.style.left   = `${OffsetLeft - 20}px`
    },
    hideDetails(){
      this.hoverBox.className = 'hoverBox HideDiv'
      this.hoverBox.innerHTML = ''      
    },
    calcPageOffsetPosY(obj) {
      if (obj.offsetParent) {
        return obj.offsetTop + this.calcPageOffsetPosY(obj.offsetParent);
      } else if (obj.y) {
        return obj.y + this.calcPageOffsetPosY(obj.offsetParent);
      } else {
        return obj.offsetTop;
      }
    },
    calcPageOffsetPosX(obj) {
      if (obj.offsetParent) {
        return obj.offsetLeft + this.calcPageOffsetPosX(obj.offsetParent);
      } else if (obj.x) {
        return obj.x + this.calcPageOffsetPosX(obj.offsetParent);
      } else {
        return obj.offsetLeft;
      }
    },
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;

      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }

      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },

    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },

    update(){
      this.filterjson()
    }
  },
};
</script>

<style>
  #UserOverviewWrap{
    float:left;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    margin-top: 80px;
    padding-bottom: 1500px;
    background-color: #f4f6fb;
    z-index: 900;
  }
  .rowsWrapper{
    float:left;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }
  .userRow{
    float: left;
    width: 100%;
    line-height: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    margin-bottom: 2px;
    /* border-bottom: 1px solid #000; */
  }
  .userDetails{
    float: left;
    width: 20%;
    text-align: right;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    /* padding-left: 1%;     */
    padding-right: 5px;
    font-size: 12px;
    /* background-color: red; */
    overflow: hidden;
    line-height: 20px;
    height: 20px;
    /* border-bottom: 1px solid #000; */
  }
  
  .userDetails.hasError{
    color: red;
  }

  .userCalGrid{
    float:left;
    position: relative;
    width: 69%;
    line-height: 20px;
    height: 20px;
    /* background-color: green; */
    white-space: nowrap;
    padding: 0;
    margin: 0;
  }

  .userCalLine{
    float:left;
    position: absolute;
    top:15px;
    left:0;
    width: 100%;
    height: 1px;
    background-color: lightgrey;
  }

  .userAvailable{
    float:left;
    position: absolute;
    top:16px;
    height: 3px;
    background-color: lightskyblue;
  }
  /* .userAvailable.nightShift{
    background-color: lightcoral;
  }
  .userAvailable.reserveShift{
    background-color: lightblue;
  }
  .userAvailable.streakShift{
    background-color: orange;
  }
  .userAvailable.holidayShift{
    background-color: green;
  } */


  .userShift{
    float:left;
    position: absolute;
    top:7px;
    height: 8px;
    background-color: grey;
    border: 1px solid grey;
  }
  .userShift:hover{
    border: 1px solid #000;
    cursor: pointer;
  }
  .userShift.nightShift{
    background-color: blue;
  }
  .userShift.reserveShift{
    background-color: black;
  }
  .userShift.streakShift{
    background-color: orange;
  }
  .userShift.holidayShift{
    background-color: red;
  }
  .userShift.shiftError{
    background-color: red;
    border: 1px solid yellow;
  }

  .hoverBox{
    float:left;
    position: absolute;
    left: 50px;
    width: auto;
    text-align: center;
    background-color:#FFF;
    border: 1px solid #aeaeae;
    padding: 30px;
    font-size: 12px;
    line-height: 14px;
  }
  .hoverBox.HideDiv{
    display: none;
  } 
  
  .hoverBox .userErrors{
    float: left;
    display: inline;
    color: red;
  }

</style>