<template>
    <section>
      <div class="columns is-gapless is-marginless is-multiline m1rem">
        <b-field class="column is-12 is-narrow mt1rem inputFieldWrap">
            <b-input 
              type="search"
              :placeholder="label" 
              v-model="searchText"
              @input="emitChange"
              style="max-width: 250px">
            </b-input>
            <b-icon
                :icon="searchText !== '' ? 'clear' : ''"
                size="is-medium"
                class="clearBtn"
                @click.native="clear">
            </b-icon>
        </b-field>
        
      </div>
    </section>
</template>

<script>

export default {
    name: "searchInput",
    
    props: {
      label: {
        type: String,
        required: false,
        default: ''
      },
      value: {
        type: String,
        required: false,
        default: ''
      }
    },
        
    data() {
      return {
        searchText: ''
      }
    },
    
    mounted(){
      this.searchText = this.value
    },
    
    methods: {
      emitChange(){
        // console.info(`emitChange:`, this.searchText)
        this.$emit('change', this.searchText)
      },
      clear(){
        this.searchText = ''        
        this.emitChange()
      }
    }
};
</script>

<style scoped>
  
  .inputFieldWrap{
    position: relative;
  }
  
  .clearBtn{
    position: absolute;
    float: right;
    left: 170px;
    top: 6px;
    z-index: 999;
    
  }
  .clearBtn:hover{
    cursor: pointer;
    color: grey;
  }
  
    
</style>
